//====================================================
//  Function: Validate forms
//====================================================

function componentValidateForms() {
  // Signup email submit
  if ($('.js-form-email').length) {
    var formValidateEmail = $('.js-form-email')
    formValidateEmail.parsley().on('form:submit', function () {
      formatFormResult(formValidateEmail)
      $('.js-hidden-content').removeClass(classIsActive)
      $('.js-hidden-content[data-content="content-name"]').addClass(
        classIsActive,
      )
      return false
    })
  }

  // Signup name submit
  if ($('.js-form-name').length) {
    var formValidateName = $('.js-form-name')

    formValidateName.parsley().on('form:submit', function () {
      formatFormResult(formValidateName)
      $('.js-hidden-content').removeClass(classIsActive)
      $('.js-hidden-content[data-content="content-services"]').addClass(
        classIsActive,
      )
      return false
    })
  }

  // Signup services submit
  if ($('.js-form-services').length) {
    var formValidateServices = $('.js-form-services')

    formValidateServices.parsley().on('form:submit', function () {
      formatFormResult(formValidateServices)

      return false
    })
  }

  window.Parsley.on('field:error', function () {
    $(this.$element).closest('.js-field-validation').addClass('has-field-error')
    $(this.$element)
      .closest('.js-field-validation')
      .removeClass('has-field-success')
  })

  window.Parsley.on('field:success', function () {
    $(this.$element)
      .closest('.js-field-validation')
      .removeClass('has-field-error')
    $(this.$element)
      .closest('.js-field-validation')
      .addClass('has-field-success')
  })

  function formatFormResult(form) {
    console.log(form)
    var unindexed_array = form.serializeArray()
    var indexed_array = {}

    $.map(unindexed_array, function (n, i) {
      indexed_array[n['name']] = n['value']
    })
    console.log('Format 1: ')
    console.log(form.serialize())
    console.log('\n')

    console.log('Format 2: ')
    console.log(form.serializeArray())
    console.log('\n')

    console.log('Format 3: ')
    console.log(indexed_array)
    console.log('\n')
  }
}
